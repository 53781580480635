
// import './dateMock'
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import '@Root/style/reset.css';
import '@Root/style/index.css';
import App from '@Root/App';
import { initializeIcons } from '@uifabric/icons'
import { ReactQueryCacheProvider, QueryCache } from 'react-query';
import { Provider } from 'jotai';
import { HelmetProvider } from "react-helmet-async";
import dayjs from "dayjs";
import "dayjs/locale/es";
import localizedFormat from "dayjs/plugin/localizedFormat";

dayjs.locale("es");
dayjs.extend(localizedFormat);

async function addPolyfills() {
  if (!window.ResizeObserver) {
    window.ResizeObserver = (await import("resize-observer-polyfill")).default;
  }
}

addPolyfills();
initializeIcons();

console.log(process?.env.REACT_APP_BACKEND_PORT);

export const queryCache = new QueryCache();
window.queryCache = queryCache;

ReactDOM.render(
  <HelmetProvider>
    <Provider>
      <ReactQueryCacheProvider queryCache={queryCache}>
        <BrowserRouter>
          <App />
        </BrowserRouter>
      </ReactQueryCacheProvider>
    </Provider>
  </HelmetProvider>,
  document.getElementById("root")
);
